<template>
  <div class="container">
    <div v-for="(story, index) of stories" :key="index" :class="['success-story', index === 0 ? 'success-story-big' : 'success-story-little']">
      <div class="success-story--image" v-if="story.field_shared_image && index === 0 " :style="`background-image:url(${endpoint}${story.field_shared_image.attributes.uri.url})`"></div>
      <div class="success-story--image" v-else-if="index===0"></div>
      <div class="success-story--left">
        <router-link :to="postLink(story, 'success_story')">
        <div class="success-story--image" v-if="story.field_shared_image && index > 0" :style="`background-image:url(${endpoint}${story.field_shared_image.attributes.uri.url})`"></div>
        </router-link>
        <div class="ribbons">
          <img v-if="story.field_ref_student" src="@/assets/icon-ribbon-student.svg">
          <img v-if="story.field_sponsor" src="@/assets/icon-ribbon-organization.svg">
        </div>
        <div class="label-ribbon">
          <div v-if="story.field_sponsor" class="success-story--partner">
            {{ story.field_sponsor.attributes.title }}
          </div>
          <div v-if="story.field_ref_student" class="success-story--student">
            {{ story.field_ref_student.attributes.title }}
          </div>
        </div>
      </div>
      <div class="success-story--right">
        <div>
          <p class="date">
            {{  formatDate(story.attributes.field_publication_date) }}
          </p>
          <h2 class="success-story--title">
            <router-link :to="postLink(story, 'success_story')">
            {{  story.attributes.title }}
            </router-link>
          </h2>
            <p class="success-story--subtitle">
              {{ story.attributes.field_subtitle }}
            </p>
        </div>
        <router-link :to="postLink(story, 'success_story')" class="read-more">Read more</router-link>
      </div>
    </div>
  </div>
</template>
<script>
import { slugify } from "@/libs/utils";

export default {
  data(){
    return {
      endpoint:process.env.VUE_APP_ENDPOINT
    }
  },
  props: {
    stories: Array,
  },
  methods: {
    postLink(story, prefix) {
      let postSlug = slugify(story.attributes.title);
      let c = `/${prefix}/${postSlug}-${story.attributes.drupal_internal__nid}`;
      console.log('c',c);
      return `/${prefix}/${postSlug}-${story.attributes.drupal_internal__nid}`;
    },
    formatDate(dateString) {
      return new Date(dateString).toLocaleDateString("en", {
        day: "numeric",
        month: "long",
        year: "numeric",
      });
    },
  },
  mounted(){

  }
};
</script>
<style lang="scss" scoped>
.date {
  margin: 0;
}
.success-story {
  margin-bottom: 40px;
  position: relative;
  word-break: break-word;

  .ribbons{
    position: relative;
    margin-top: 0;
    img {
      margin-right: 10px;
    }
  }

  @media (min-width:992px) {
    display: flex;
  }

  .label-ribbon {
    margin: 0;
  }

  .read-more {
        margin-bottom: 0;
    margin-top: 3rem;
  }
}

.success-story--right{
  margin: 1rem 0rem;
}
.success-story--left{
  position: relative;
  width: 100%;
  max-width: 100%;
  height: 235px;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  padding-left: 15px;
  margin: 0;
  padding-bottom: 0px;

  @media (min-width:992px) {
    margin-right: 10px;
  }

}
.success-story-big{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-bottom: 20px;
  min-height: 470px;
  padding-left: 15px;

  

  .success-story--left{
    width: 100%;
    height: 100%;
    padding-left: 0;
  }
  .success-story--right{
    position: relative;
   
  }
  a{
    color: #1DC07E;
  }
  p{
    color: #fff;
  }
}

.success-story-little {
  background-color: #f6f6f6;

  .success-story--right {
    padding: 0px 15px 15px 15px;
  }
}

.success-story--title{
  color: #1DC07E;
  font-size: 24px;
  line-height: 1.3em;
}
.success-story--image{
  overflow: hidden;
  margin: 0;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  &::after{
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    background: transparent linear-gradient(90deg, #000000 0%, rgba(0, 0, 0, 0) 100%) 0% 0% no-repeat padding-box;  
    }
}
.success-story--student,
.success-story--partner{
  position: relative;
  color: #fff;
  text-transform: uppercase;
}



@media (min-width:576px){
  .success-story-big {
    .success-story--partner,
    .success-story--student {
      margin: 1rem 0rem;
    }
  }
}
 @media (min-width:768px) {

    .success-story-little {
      .label-ribbon {
        margin-top: 20px;
      }
    }

    .success-story--title {
      font-size: 2rem;
    }

    .success-story-big{
      padding: 0px 155px 35px 45px;
    }

    .success-story--left {
      padding: 0px 10px 10px 45px;
    }

    .success-story-little .success-story--right {
      padding: 13px 45px 35px 45px;
    }

 

  }

 @media (min-width:992px) {
    .success-story--left {
      width: 430px;
    }
    .success-story-little .success-story--right {
    padding: 6px 35px 0px 30px;
}
  }
</style>