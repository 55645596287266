<template>
  <div v-if="pageData && pageData.data" class="intro-section container">
    <div class="col-lg-6">
      <h1 class="light" v-html="pageData.data.attributes.title"></h1>
      <p
        v-if="pageData.data.attributes.body"
        v-html="pageData.data.attributes.body.value"
      ></p>
      <!-- <div class="container" v-if="this.$route.name == 'Home'">
        <div class="mt-5">
          <button class="cta-outline mx-lg-4">For Students</button>
          <button class="cta-outline mx-lg-4">For Organizations</button>
        </div>
      </div> -->
    </div>
  </div>
</template>
<script>
export default {
  name: "intro-page",
  props: {
    pageId: String,
  },
  data() {
    return {
      pageData: null,
    };
  },
  created() {
    fetch(`${process.env.VUE_APP_ENDPOINT}/jsonapi/node/page/${this.pageId}`, {
      method: "GET",
      mode: "cors",
    })
      .then((response) => {
        return response.json();
      })
      .then((json) => {
        this.pageData = json;
      })
      .catch(function (error) {
        console.log("Request failed", error);
      });
  },
};
</script>
<style lang="scss" >
/*.home .intro-full-w {
  background: url("../assets/home-intro.png") no-repeat;
  background-position: center right;
  background-size: contain;
}*/
</style>