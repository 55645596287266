<template>
  <div class="success-stories">
    <top-decorations></top-decorations>
    <div class="small-intro">
      <intro-page :pageId="currentPageId"></intro-page>
      <success-story-list
        v-if="stories"
        :stories="stories"
      ></success-story-list>
    </div>
  </div>
</template>
<script>
import IntroPage from "../components/IntroPage.vue";
import TopDecorations from "../components/TopDecorations.vue";
import SuccessStoryList from "../components/SuccessStoryList.vue";
import { fetchNodes } from "@/libs/drupalClient";

export default {
  name: "success-stories",
  components: {
    IntroPage,
    TopDecorations,
    SuccessStoryList,
  },
  data() {
    return {
      currentPageId: "d169da8c-9d18-47dd-89bf-94e4d64fb8ed",
      stories: null,
    };
  },
  methods: {
    fetchStories() {
      fetchNodes("success_story", {include: ["field_sponsor", "field_shared_image", "field_ref_student"]})
        .then((nodes)=>{
          this.stories = nodes;
      });
    },
  },
  mounted() {
    this.fetchStories();
  },
};
</script>
<style lang="scss" scoped>
</style>